<template>
	<div class="mainTem">

		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				{{ $t('Storage.nav.A_undertakes_to') }}

				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcelAction()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{ $t('Storage.nav.A_undertakes_to') }}</span>
						<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">

					<!-- <ul class="actionConList"> -->
					<!-- <li> -->
					<el-button type="success" @click="toCreate()" size="small"
						icon="el-icon-plus">{{ $t('i18nn_e8564657bbe9ca53') }}</el-button>
					<!-- </li> -->

					<!-- <li v-if="'0'==filterData.exprStatus"> -->
					<el-button type="primary" @click="commitAction($event, null)" size="small" icon="el-icon-sell"
						v-if="'0' == filterData.exprStatus">
						{{ $t('Storage.DropShipping.Batch_submission') }}
					</el-button>
					<!-- </li> -->
					<!-- <li v-if="''==filterData.exprStatus||'10'==filterData.exprStatus"> -->
					<el-button type="danger" @click="revocationAction($event, null)" size="small" icon="el-icon-minus"
						v-if="'' == filterData.exprStatus || '10' == filterData.exprStatus">{{ $t('i18nn_b4e5993f97fe04ee') }}</el-button>
					<!-- </li> -->
					<!-- <li v-if="'5'==filterData.exprStatus"> -->
					<el-button @click="abnormalReSubmit($event, null)" type="danger" size="small" icon="el-icon-document"
						v-if="'5' == filterData.exprStatus">{{ $t('i18nn_735df2dca58c4f9b') }}</el-button>
					<!-- </li> -->
					<!-- <li> -->
					<el-button @click="openUploadLabelFile()" type="warning" size="small" icon="el-icon-upload2"
						v-if="'' == filterData.exprStatus || '0' == filterData.exprStatus || '10' == filterData.exprStatus">
						{{ $t('i18nn_bd6d1ded173dc477') }}
					</el-button>
					<!-- </li> -->
					<!-- <li
							v-if="''==filterData.exprStatus||'0'==filterData.exprStatus||'10'==filterData.exprStatus||'20'==filterData.exprStatus"> -->
					<el-button @click="openBatchPrtOrder()" type="primary" size="small" icon="el-icon-document"
						v-if="'' == filterData.exprStatus || '0' == filterData.exprStatus || '10' == filterData.exprStatus">{{ $t('i18nn_3f2ee05e57ec8c0b') }}</el-button>
					<!-- </li> -->

					<!-- <li v-if="'3'==filterData.exprStatus"> -->
					<el-button @click="moveToAbnormal($event, null)" type="danger" size="small" icon="el-icon-d-arrow-right"
						v-if="'3' == filterData.exprStatus">{{ $t('i18nn_084d695b245685c3') }}</el-button>
					<!-- </li> -->





					<!-- <li>
							<el-button @click="openLocalPdfSplit($event, null)" type="primary" plain size="small" icon="el-icon-d-caret">
								本地PDF分割</el-button>
						</li>
						<li>
							<el-button @click="openLocalPdfRotate($event, null)" type="primary" plain size="small" icon="el-icon-refresh">
								本地PDF旋转</el-button>
						</li> -->

					<!-- <li> -->
					<el-dropdown size="small" @command="handleMoreAction($event)">
						<el-button type="primary" plain size="small" style="margin:0 10px">
							<span>PDF<span>{{ $t('i18nn_8dc3003658b13cca') }}</span></span><i
								class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="openLocalPdfSplit" icon="el-icon-d-caret">
								<span>{{ $t('i18nn_74a5def3116ecd89') }}</span>PDF<span>{{ $t('i18nn_f156a9536049f461') }}</span>
							</el-dropdown-item>
							<el-dropdown-item divided command="openLocalPdfRotate" icon="el-icon-refresh-right">
								<span>{{ $t('i18nn_45855d1e066e1764') }}</span>PDF<span>{{ $t('i18nn_f156a9536049f461') }}</span>
							</el-dropdown-item>
							<el-dropdown-item divided command="openLocalPdfMerge" icon="el-icon-files">
								<span>{{ $t('i18nn_6fd4956e676cec49') }}</span>PDF<span>{{ $t('i18nn_f156a9536049f461') }}</span>
							</el-dropdown-item>
							<el-dropdown-item divided command="openLocalImgConvertPdf" icon="el-icon-picture-outline-round">
								<span>图片转</span>PDF<span>{{ $t('i18nn_f156a9536049f461') }}</span>
							</el-dropdown-item>



						</el-dropdown-menu>
					</el-dropdown>
					<!-- </li> -->

					<!-- </ul> -->

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.exprStatus" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>
							<!-- {{ item.label }} -->
							{{ i18nFormatter(item.value) }}
						</span>
						<span class="btn-badge" v-if="'0' == item.value && !!countData.draf && '0' != countData.draf">{{
					countData.draf }}</span>
						<span class="btn-badge"
							v-if="'3' == item.value && !!countData.checkPending && '0' != countData.checkPending">{{
					countData.checkPending }}</span>
						<span class="btn-badge" v-if="'5' == item.value && !!countData.checkFail && '0' != countData.checkFail">{{
					countData.checkFail }}</span>
						<span class="btn-badge"
							v-else-if="'10' == item.value && !!countData.commited && '0' != countData.commited">{{ countData.commited
							}}</span>
						<span class="btn-badge" v-else-if="'20' == item.value && !!countData.verify && '0' != countData.verify">{{
					countData.verify }}</span>
						<span class="btn-badge" v-else-if="'30' == item.value && !!countData.pling && '0' != countData.pling">{{
					countData.pling }}</span>
						<span class="btn-badge"
							v-else-if="'35' == item.value && !!countData.waitSend && '0' != countData.waitSend">{{ countData.waitSend
							}}</span>
						<span class="btn-badge" v-else-if="'50' == item.value && !!countData.return && '0' != countData.return">{{
					countData.return }}</span>
						<span class="btn-badge" v-else-if="'60' == item.value && !!countData.stoped && '0' != countData.stoped">{{
					countData.stoped }}</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">

				<ul class="filterConList">
					<!-- <li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
					</li> -->
					<!-- <li>
						<span>{{$t('i18nn_d50e79aeea569249')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span>{{$t('i18nn_8bf7c69409f84506')}}</span>
						<whNoSelect @changeData="changWhNo2"></whNoSelect>
					</li> -->
					<!-- <li>
						<span>{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
							size="small" @change="initData()" :picker-options="pickerOptions"></el-date-picker>
					</li> -->
					<li>
						<span class="filterName">{{ $t('i18nn_f6283c46ba925ab4') }}</span>
						<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
					</li>
					<li>
						<span class="filterName">{{ $t('i18nn_43a3586339251494') }}</span>
						<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
							@change="initData()" :picker-options="pickerOptions" style="width: 220px"></el-date-picker>
					</li>
					<!-- <li>
						<span class="filterName">{{$t('i18nn_0f85a73e30bce3c5')}}</span>
						<el-select filterable clearable v-model="filterData.carrier" :placeholder="$t('FormMsg.Please_select')"
							size="small" @change="initData()" style="width: 100px;">
							<el-option v-for="item in selectOption.wh_carrier_code" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code"></el-option>
						</el-select>
					</li> -->
					<li>
						<span>{{ $t('i18nn_abf9f4b8c93d641e') }}</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.orderNumber"
							maxlength="50" :clearable="true" style="width: 200px;"></el-input>
						<!-- <el-button icon="el-icon-magic-stick" size="small" type="success" plain
							@click="openOrderInputAction()">{{$t('i18nn_5b16dd13f1bc0e1c')}}</el-button> -->

						<!-- <el-popover placement="top" trigger="hover"
							:content="$t('i18nn_5b16dd13f1bc0e1c')">
							<el-button slot="reference" icon="el-icon-magic-stick" size="small" type="success" plain
								@click="openOrderInputAction()"></el-button>
						</el-popover> -->

						<el-tooltip class="" effect="dark" :content="$t('i18nn_5b16dd13f1bc0e1c')" placement="top">
							<el-button icon="el-icon-s-operation" size="small" type="success" plain
								@click="openOrderInputAction()"></el-button>
						</el-tooltip>

					</li>
					<!-- <li>
						<span>{{$t('i18nn_0f85a73e30bce3c5')}}</span>
						<el-select filterable clearable v-model="filterData.carrier" :placeholder="$t('FormMsg.Please_select')"
							size="small" style="width: 100px;" @change="initData()">
							<el-option v-for="item in selectOption.wh_carrier_code" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code"></el-option>
						</el-select>
					</li> -->
					<!-- <li>
						<span class="">{{$t('i18nn_f6283c46ba925ab4')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium" :width="'100'"></whNoSelect>
					</li> -->
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
					</li>
					<!-- <li>
						<span>{{ $t('i18nn_6235565b185f0725') }}</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.workNo"
							maxlength="50" :clearable="true" style="width: 220px;"></el-input>
					</li> -->

					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<li>
						<el-button :icon="drawerFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" size="small" type="primary"
							@click="openMoreFilter()"></el-button>
					</li>
				</ul>
			</div>
		</div>


		<!--更多筛选-->
		<!-- <el-drawer :wrapperClosable="false"
			 append-to-body
			custom-class="drawerFilter"
		  :title="$t('i18nn_c4ca802cf2230b0b')"
		  :visible.sync="drawerFilter"
		  :direction="'rtl'"> -->
		<div class="drawerFilterCon" v-show="drawerFilter">
			<ul class="drawerFilterList">

				<li>
					<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
					<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
				</li>

				<li>
					<span>出库编号</span>
					<el-input type="text" v-model="filterData.sysDefOrderNum" size="small" clearable
						@keyup.enter.native="initData()" maxlength="300" :placeholder="$t('hytxs0000001')" style="width: 220px;" />
					<el-tooltip class="" effect="dark" :content="'多出库编号搜索'" placement="top">
						<el-button icon="el-icon-s-operation" size="small" type="success" plain
							@click="openSysNoInputAction()"></el-button>
					</el-tooltip>
				</li>

				<li>
					<span>快递单号</span>
					<el-input type="text" v-model="filterData.tracking" size="small" clearable @keyup.enter.native="initData()"
						maxlength="300" :placeholder="$t('hytxs0000001')" style="width: 220px;" />
					<el-tooltip class="" effect="dark" :content="'多快递单号搜索'" placement="top">
						<el-button icon="el-icon-s-operation" size="small" type="success" plain
							@click="openTrackingInputAction()"></el-button>
					</el-tooltip>
				</li>

				<li>

					<span>发货单类型</span>:
					<el-select filterable clearable v-model="filterData.wh_exce_order_type" :placeholder="$t('2ad108ab2c560530')"
						size="small" @change="initData()" style="width: 100px;">
						<el-option v-for="item in selectOption.wh_exce_order_type" :key="item.code"
							:label="$Utils.i18nKeyText(item, 'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item, 'codeText') }}</span>
							</div>
						</el-option>
						<el-option :label="$t('16853bda54120bf1')" :value="null"></el-option>
					</el-select>

					<!-- <el-radio-group v-model="filterData.wh_exce_order_type" @change="initData()">
						<el-radio :label="null">{{$t('16853bda54120bf1')}}</el-radio>
						<el-radio v-for="item in selectOption.wh_exce_order_type" :key="item.code"
							:label="item.code">
								{{ $Utils.i18nKeyText(item,'codeText') }}
							</el-radio>
					</el-radio-group> -->

				</li>

				<!-- <li>
					<span class="filterName">{{$t('i18nn_f6283c46ba925ab4')}}</span>
					<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
				</li> -->
				<!-- <li>
					<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
					<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
						range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
						:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
						@change="initData()" :picker-options="pickerOptions"></el-date-picker>
				</li> -->
				<li>
					<span class="filterName">{{ $t('i18nn_8468ac93161085d6') }}</span>
					<el-select filterable clearable v-model="filterData.isPriority" :placeholder="$t('FormMsg.Please_select')"
						size="small" @change="initData()">
						<el-option :label="$t('i18nn_02ccd2cf723f9272')" :value="'1'"></el-option>
						<el-option :label="$t('i18nn_e76d885ae1c74d4f')" :value="'0'"></el-option>
					</el-select>
				</li>
				<li>
					<span class="filterName">{{ $t('i18nn_1324a1d91ae3de53') }}</span>
					<el-select filterable clearable v-model="filterData.isLabelUrl" :placeholder="$t('FormMsg.Please_select')"
						size="small" @change="initData()">
						<el-option :label="$t('i18nn_27e24ff9191e254d')" :value="1"></el-option>
						<el-option :label="$t('i18nn_19ec51d5bd5fc832')" :value="2"></el-option>
					</el-select>
				</li>
				<li>
					<span class="filterName">{{ $t('i18nn_0f85a73e30bce3c5') }}</span>
					<el-select filterable clearable v-model="filterData.carrier" :placeholder="$t('FormMsg.Please_select')"
						size="small" @change="initData()">
						<el-option v-for="item in selectOption.wh_carrier_code" :key="item.code" :label="$Utils.i18nCodeText(item)"
							:value="item.code"></el-option>
					</el-select>
				</li>
				<li>
					<!-- <el-form-item :label="$t('i18nn_3601b5a98803f4d7')" prop="" required>
							<el-select filterable clearable v-model="form.isSelfLabel" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
								<el-option v-for="item in selectOption.biz_wh_is_shelf_label" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
							</el-select>
						</el-form-item> -->
					<span class="filterName">{{ $t('i18nn_3601b5a98803f4d7') }}</span>
					<el-select filterable clearable v-model="filterData.isSelfLabel" :placeholder="$t('FormMsg.Please_select')"
						size="small" @change="initData()">
						<el-option v-for="item in selectOption.biz_wh_is_shelf_label" :key="item.code"
							:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
					</el-select>
				</li>



				<!-- <li>
						<span class="filterName">{{$t('i18nn_ade48dee835e25c0')}}</span>
						<el-select filterable clearable v-model="filterData.signatureService"
							:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 100px;"
							@change="initData()">
							<el-option v-for="item in selectOption.wh_dic_signature_service" :key="item.code"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option>
						</el-select>
					</li> -->
				<!-- <li>
						<span class="filterName">{{$t('i18nn_f6283c46ba925ab4')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li> -->
				<li>
					<span class="filterName">{{ $t('i18nn_b4296642bcc9f428') }}</span>
					<whNoSelect @changeData="changWhNo2" size="small"></whNoSelect>
				</li>
				<li class="filterBtnCon">
					<el-button icon="el-icon-search" size="small" type="primary"
						@click="queryMoreFilter()">{{ $t('i18nn_1e7246dd6ccc5539') }}</el-button>
				</li>
			</ul>
		</div>

		<!-- 多订单号搜索显示 -->
		<div class="" style="padding: 0 10px 10px 10px;"
			v-if="filterData.orderNumberList && filterData.orderNumberList.length > 0">
			<!-- <div slot="header" class="">
				<span><span>undefined</span>;<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><strong>{{filterData.orderNumberList.length}}</strong>;</span>
				<el-button icon="el-icon-close" size="small" type="success" @click="clearOrderList">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
			</div> -->

			<ul class="filterConList" style="text-align: left;">
				<el-button icon="el-icon-close" size="small" type="success" @click="clearOrderList()">
					<span>{{ $t('i18nn_4c4e546aa3683ff5') }}</span>
					(<span>多订单号搜索</span>)
					(<span>共<span>{{ filterData.orderNumberList.length }}</span></span>)
				</el-button>
				<!-- <li><span>{{$t('i18nn_abf9f4b8c93d641e')}}</span>;共<strong>{{filterData.orderNumberList.length}}</strong></li> -->
				<li v-for="(item, index) in filterData.orderNumberList" :key="index">
					<el-tag size="small" effect="plain">{{ item }}</el-tag>
				</li>
				<!-- <li>
		  		<el-button icon="el-icon-close" size="small" type="success" plain
		  			@click="clearOrderList">undefined</el-button>
		  	</li> -->
			</ul>
		</div>

		<!-- 多系统编号搜索显示 -->
		<div class="" style="padding: 0 10px 10px 10px;"
			v-if="filterData.sysDefOrderNumList && filterData.sysDefOrderNumList.length > 0">

			<ul class="filterConList" style="text-align: left;">
				<el-button icon="el-icon-close" size="small" type="success" @click="clearSysNoList()">
					<span>{{ $t('i18nn_4c4e546aa3683ff5') }}</span>
					(<span>多出库编号搜索</span>)
					(<span>共<span>{{ filterData.sysDefOrderNumList.length }}</span></span>)
				</el-button>
				<li v-for="(item, index) in filterData.sysDefOrderNumList" :key="index">
					<el-tag size="small" effect="plain">{{ item }}</el-tag>
				</li>
			</ul>
		</div>

		<!-- 多快递单号搜索显示 -->
		<div class="" style="padding: 0 10px 10px 10px;"
			v-if="filterData.trackingList && filterData.trackingList.length > 0">

			<ul class="filterConList" style="text-align: left;">
				<el-button icon="el-icon-close" size="small" type="success" @click="clearTrackingList()">
					<span>{{ $t('i18nn_4c4e546aa3683ff5') }}</span>
					(<span>多快递单号搜索</span>)
					(<span>共<span>{{ filterData.trackingList.length }}</span></span>)
				</el-button>
				<li v-for="(item, index) in filterData.trackingList" :key="index">
					<el-tag size="small" effect="plain">{{ item }}</el-tag>
				</li>
			</ul>
		</div>
		<!-- </el-drawer> -->
		<!-- <div class="wh-filter-con">
			<div class="">
				<span>{{ $t('Storage.packing_list.status') }}</span>
				<el-radio-group v-model="filterData.exprStatus" size="medium" @change="serPageData()">
					<el-radio-button border v-for="item in selectOption.statusList" :key="item.value"
						:label="item.value">
						<span>{{ item.label }}</span>
						<span class="btn-badge"
							v-if="'0' == item.value && !!countData.draf && '0' != countData.draf">{{ countData.draf }}</span>
						<span class="btn-badge"
							v-else-if="'10' == item.value && !!countData.commited && '0' != countData.commited">{{ countData.commited }}</span>
						<span class="btn-badge"
							v-else-if="'20' == item.value && !!countData.verify && '0' != countData.verify">{{ countData.verify }}</span>
						<span class="btn-badge"
							v-else-if="'30' == item.value && !!countData.pling && '0' != countData.pling">{{ countData.pling }}</span>
						<span class="btn-badge"
							v-else-if="'35' == item.value && !!countData.waitSend && '0' != countData.waitSend">{{ countData.waitSend }}</span>
						<span class="btn-badge"
							v-else-if="'50' == item.value && !!countData.return && '0' != countData.return">{{ countData.return }}</span>
						<span class="btn-badge"
							v-else-if="'60' == item.value && !!countData.stoped && '0' != countData.stoped">{{ countData.stoped }}</span>
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_d50e79aeea569249')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_8bf7c69409f84506')}}</span>
				<whNoSelect @changeData="changWhNo2"></whNoSelect>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_43a3586339251494')}}</span>
				<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
					range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
					:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
					size="small" @change="initData()" :picker-options="pickerOptions"></el-date-picker>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_abf9f4b8c93d641e')}}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.orderNumber"
					maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
					maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_6235565b185f0725') }}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.workNo"
					maxlength="50" :clearable="true" style="width: 220px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_0f85a73e30bce3c5')}}</span>
				<el-select filterable clearable v-model="filterData.carrier" :placeholder="$t('FormMsg.Please_select')"
					size="small" style="width: 100px;" @change="initData()">
					<el-option v-for="item in selectOption.wh_carrier_code" :key="item.code" :label="$Utils.i18nCodeText(item)"
						:value="item.code"></el-option>
				</el-select>
			</div>
			<el-button type="primary" @click="initData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
			<el-button type="success" @click="toCreate" size="small" icon="el-icon-upload">
				{{ $t('Storage.DropShipping.Import') }}
			</el-button>
			<el-button type="primary" @click="commitAction($event, null)" size="small" icon="el-icon-sell">
				{{$t('Storage.DropShipping.Batch_submission')}}
			</el-button>
			<el-button @click="openLocalPdfSplit($event, null)" type="primary" size="mini" icon="el-icon-d-caret">
				本地PDF分割</el-button>
			<el-button @click="openLocalPdfRotate($event, null)" type="primary" size="mini" icon="el-icon-refresh">
				本地PDF旋转</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				@selection-change="handleSelectionChange" @row-click="handleCurrentChange" :row-class-name="tableRowClassName"
				:max-height="$store.state.frameConHeightWh1">
				<!-- <el-table-column type="expand" fixed="left">
					<template slot-scope="scope">
						<div v-if="scope.row && scope.row.expressSkuList && scope.row.expressSkuList.length > 0"
							style="margin-left:50px">
							<el-table :data="scope.row.expressSkuList" :border="true" style="width: 100%;" size="small">
								<el-table-column type="index" :index="$Utils.tableIndex(pagination)" width="50" align="center"
									:label="$t('Storage.tableColumn.no')"></el-table-column>

								<el-table-column prop="goodsSku" label="SKU"></el-table-column>

								<el-table-column prop="whNo" :label="$t('Storage.skuInfo.warehouse')"></el-table-column>

								<el-table-column prop="quantity" :label="$t('Storage.DropShipping.quantity')">
								</el-table-column>

								<el-table-column prop="goodsSku" :label="$t('Storage.skuInfo.SKU_title')"
									min-width="100">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div>{{ scope2.row.mySkuDto.goodsName }}</div>
											<div>{{ scope2.row.mySkuDto.goodsNameEn }}</div>
										</div>
									</template>
</el-table-column>

<el-table-column prop="goodsSku" :label="$t('Storage.skuInfo.SKU_pictures')" min-width="100">
	<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div>
												<el-image lazy style="width: auto; height: 40px" fit="contain"
													:src="scope.row.goodsImg">
													<div slot="error" class="image-slot" style="font-size: 40px;"><i
															class="el-icon-picture-outline"></i></div>
												</el-image>
											</div>
										</div>
									</template>
</el-table-column>

<el-table-column prop="mySkuDto" :label="$t('Storage.skuInfo.unit')" min-width="80">
	<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">{{ scope2.row.mySkuDto.sizeUnitName }}</div>
									</template>
</el-table-column>

<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')" min-width="80">
	<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">{{ scope2.row.mySkuDto.goodsWeight }}</div>
									</template>
</el-table-column>

<el-table-column prop="mySkuDto" :label="$t('Storage.skuInfo.length_width_height')" min-width="80">
	<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											{{ scope2.row.mySkuDto.goodsLength }}*{{ scope2.row.mySkuDto.goodsWidth }}*{{ scope2.row.mySkuDto.goodsHeight }}
										</div>
									</template>
</el-table-column>
</el-table>
</div>
<div v-else>{{ $t('Storage.DropShipping.no_SKU') }}</div>
</template>
</el-table-column> -->
				<el-table-column type="selection" fixed="left" align="center" width="50">
				</el-table-column>
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="exprStatusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">

						<div class="tag-color-grey" v-if="'0' == scope.row.exprStatus">
							<!-- {{ scope.row.exprStatusName }} -->
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
						<div class="tag-color-blue" v-else-if="'3' == scope.row.exprStatus">
							<!-- {{ scope.row.exprStatusName }} -->
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
						<div class="tag-color-blue" v-else-if="'10' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
						<div class="tag-color-cyan" v-else-if="'20' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
						<div class="tag-color-green" v-else-if="'30' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
						<div class="tag-color-yellow" v-else-if="'35' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
						<div class="tag-color-orange" v-else-if="'40' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
						<div class="tag-color-red" v-else-if="'50' == scope.row.exprStatus">
							<div>{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}</div>
						</div>
						<div class="tag-color-brown" v-else-if="'60' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
						<div class="tag-color-purple" v-else>
							{{ $Utils.i18nKeyText(scope.row, 'exprStatusName') }}
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column> -->

				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_43a3586339251494')"></el-table-column> -->
				<el-table-column prop="sysDefOrderNum" :label="$t('i18nn_7f6c721b57ed0e7b')" min-width="100">
					<template slot-scope="scope">
						<div>
							<!-- <el-link type="primary" size="mini" icon="" @click="toDetActionView($event, scope.row, scope.$index)">
								{{ scope.row.sysDefOrderNum }}
							</el-link> -->
							<div class="link-copy">
								<span class="link-text" @click="toDetActionView($event, scope.row, scope.$index)">
									{{ scope.row.sysDefOrderNum }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
									placement="top">
									<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.sysDefOrderNum)"></i>
								</el-tooltip>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column> -->
				<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')" min-width="150">
					<template slot-scope="scope">
						<!-- <div>
							<span>{{ scope.row.orderNumber }}</span>

							<div v-if="scope.row.orderTypeName">
								<el-tag type="success" v-if="10==scope.row.orderType">
									{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
								</el-tag>
								<el-tag type="warning" v-else-if="20==scope.row.orderType">
									{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
								</el-tag>
								<el-tag type="info" v-else>
									{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
								</el-tag>
							</div>
						</div> -->

						<div>
							<ul>
								<li v-if="scope.row.isPriority && '1' == scope.row.isPriority">
									<div>
										<el-tag type="danger" size="small" effect="plain" v-if="'1' == scope.row.isPriority">优先操作</el-tag>
										<!-- <el-tag type="info"  size="small" effect="plain" v-else>{{ $Utils.i18nKeyText(scope.row,'isPriorityName') }}</el-tag> -->
										<!-- <el-tag type="warning" v-else-if="20==scope.row.orderType">
												{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
											</el-tag>
											<el-tag type="info" v-else>
												{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
											</el-tag> -->
									</div>
								</li>

								<li>
									<!-- <span>{{$t('i18nn_abf9f4b8c93d641e')}}</span>: -->
									<span>{{ scope.row.orderNumber }}</span>
								</li>
								<li v-if="scope.row.orderTypeName">
									<div>
										<el-tag type="success" size="small" v-if="10 == scope.row.orderType">
											{{ $Utils.i18nKeyText(scope.row, 'orderTypeName') }}
										</el-tag>
										<el-tag type="warning" size="small" v-else-if="20 == scope.row.orderType">
											{{ $Utils.i18nKeyText(scope.row, 'orderTypeName') }}
										</el-tag>
										<el-tag type="info" size="small" v-else>
											{{ $Utils.i18nKeyText(scope.row, 'orderTypeName') }}
										</el-tag>
									</div>
								</li>
								<li v-if="scope.row.thirdOrderNumber">
									<span>{{ $t('i18nn_83b1b50eb00a9fb6') }}</span>:
									<span>{{ scope.row.thirdOrderNumber }}</span>
								</li>
								<li v-if="scope.row.opRemark">
									<span>标记</span>:
									<strong class="red">{{ scope.row.opRemark }}</strong>
								</li>
								<!-- <li>
										<span>{{$t('i18nn_581bc71c027386cb')}}</span>：
										<span>
											<el-link v-if="scope.row.tracking" type="primary" size="mini" icon=""
												@click="toExpLgsTrackView($event, scope.row, scope.$index)">
												{{ scope.row.tracking }}
											</el-link>
										</span>
									</li>
									<li>
										<span>{{$t('i18nn_e83443dd6c718cf9')}}</span>：
										<span style="color:#E6A23C;">{{scope.row.derivedStatus}}</span>
									</li>
									<li>
										<span>{{$t('i18nn_41d020489ecacbf0')}}</span>：
										<span style="color:#333333;">{{scope.row.trackDate}}</span>
									</li> -->
							</ul>
						</div>
					</template>
				</el-table-column>


				<!-- <el-table-column prop="isPriorityName" :label="$t('i18nn_7a29cab1a4b8f231')">
					<template slot-scope="scope">
						<div>
							<el-tag type="success"
								v-if="'1' == scope.row.isPriority">{{ $Utils.i18nKeyText(scope.row,'isPriorityName') }}</el-tag>
							<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'isPriorityName') }}</el-tag>
						</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="thirdOrderNumber" :label="$t('i18nn_83b1b50eb00a9fb6')"></el-table-column> -->


				<!-- <el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')"></el-table-column> -->

				<!-- <el-table-column prop="tracking" :label="$t('i18nn_581bc71c027386cb')"></el-table-column> -->

				<el-table-column prop="carrier" :label="$t('i18nn_e63fc078a7baf7c9')" min-width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{ $t('i18nn_b0c50b6ff9674173') }}</span>：
									<span>{{ scope.row.carrier }}</span>
								</li>
								<li>
									<span>{{ $t('i18nn_581bc71c027386cb') }}</span>：
									<!-- <span>
										<el-link v-if="scope.row.tracking" type="primary" size="mini" icon=""
											@click="toExpLgsTrackView($event, scope.row, scope.$index)">
											{{ scope.row.tracking }}
										</el-link>
									</span> -->
									<span class="link-copy" v-if="scope.row.tracking">
										<span class="link-text" @click="toExpLgsTrackView($event, scope.row, scope.$index)">
											{{ scope.row.tracking }}
										</span>
										<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
											placement="top">
											<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.tracking)"></i>
										</el-tooltip>
									</span>
								</li>
								<li>
									<span>{{ $t('i18nn_e83443dd6c718cf9') }}</span>：
									<span style="color:#E6A23C;">{{ scope.row.derivedStatus }}</span>
								</li>
								<li>
									<span>{{ $t('i18nn_41d020489ecacbf0') }}</span>：
									<span style="color:#333333;">{{ scope.row.trackDate }}</span>
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="isSelfLabel" :label="$t('i18nn_3601b5a98803f4d7')" min-width="200">
					<template slot-scope="scope">


						<div>
							<ul>
								<!-- <li>
									<div v-if="scope.row.isSelfLabel">
										<el-tag type="warning" size="small" v-if="'10' == scope.row.isSelfLabel">{{$t('i18nn_198d8fd115bfc3d9')}}</el-tag>
										<el-tag type="success" size="small" v-else-if="'20' == scope.row.isSelfLabel">{{$t('i18nn_15bc1e82df3428d8')}}</el-tag>
										<el-tag type="info" size="small" v-else>{{ scope.row.isSelfLabel }}</el-tag>
									</div>
								</li> -->

								<li>
									<span>
										<el-tag type="warning" size="small"
											v-if="'10' == scope.row.isSelfLabel">{{ $t('i18nn_198d8fd115bfc3d9') }}</el-tag>
										<el-tag type="success" size="small"
											v-else-if="'20' == scope.row.isSelfLabel">{{ $t('i18nn_15bc1e82df3428d8') }}</el-tag>
										<el-tag type="info" size="small" v-else>{{ scope.row.isSelfLabel }}</el-tag>
									</span>
									<span>{{ $t('i18nn_1324a1d91ae3de53') }}</span>:
									<!-- <span>{{scope.row.derivedStatus}}</span> -->
									<div class="over_ellipsis" style="max-width: 100%;">
										<a v-if="scope.row.labelInfoDto" :href="scope.row.labelInfoDto.labelUrl"
											:title="scope.row.labelInfoDto.labelUrl" target="_blank">
											{{ scope.row.labelInfoDto.labelUrl }}
										</a>
									</div>

									<div class="over_ellipsis" style="max-width: 100%;">
										<a v-if="scope.row.labelUrl" :href="scope.row.labelUrl" :title="scope.row.labelUrl"
											target="_blank">{{ scope.row.labelUrl }}</a>
									</div>
								</li>
								<li>
									<span>{{ $t('i18nn_90429ac39f4f4634') }}</span>：
									<span>
										{{ scope.row.labelStatusPressName }}
									</span>
								</li>
								<li>
									<span>{{ $t('i18nn_d7315fb8114eb446') }}</span>：
									<span>{{ scope.row.rate }}</span>
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="labelStatusPressName" :label="$t('i18nn_90429ac39f4f4634')"></el-table-column>

				<el-table-column prop="labelUrl" :label="$t('i18nn_1324a1d91ae3de53')">
					<template slot-scope="scope">
						<div class="over_ellipsis" style="max-width: 100%;">
							<a v-if="scope.row.labelInfoDto" :href="scope.row.labelInfoDto.labelUrl"
								:title="scope.row.labelInfoDto.labelUrl" target="_blank">
								{{ scope.row.labelInfoDto.labelUrl }}
							</a>
						</div>
						<div class="over_ellipsis" style="max-width: 100%;">
							<a v-if="scope.row.labelUrl" :href="scope.row.labelUrl" :title="scope.row.labelUrl"
								target="_blank">{{ scope.row.labelUrl }}</a>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="rate" :label="$t('i18nn_d7315fb8114eb446')"></el-table-column> -->

				<el-table-column prop="goodsSku" label="SKU" min-width="100">
					<template slot-scope="scope">
						<div class="recDet" v-if="scope.row.expressSkuList">
							<!-- <span v-for="(item,index) in scope2.row.expressSkuList"
								:key="index">{{item.goodsSku}},</span> -->
							<!-- {{scope2.row.expressSkuList.map(item=>{
									return item.goodsSku
								}).join(',')}} -->
							<!-- <div class="recDet"> -->
							<!-- {{item.goodsSku}} -->
							<div class="recItem" v-for="(item, index) in scope.row.expressSkuList" :key="index">
								<span class="">{{ item.goodsSku }}</span>&nbsp;*&nbsp;
								<strong class="">{{ item.quantity }}</strong>
							</div>
							<div v-if="scope.row.expressSkuList.length > 5">
								<el-link type="primary" @click="toActionView($event, scope.row, scope.$index)">
									..
									<span>{{ $t('i18nn_73bbf51487ec69e9') }}</span>
									<span>&nbsp;{{ scope.row.expressSkuList.length }}&nbsp;</span>
									<span>{{ $t('i18nn_117aa3f27e5e430c') }}</span>
								</el-link>
							</div>
							<!-- </div> -->
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="packingNo" :label="$t('i18nn_b7a10fe4e9528362')"></el-table-column> -->
				<!-- <el-table-column prop="senderAddr" :label="$t('i18nn_f6283c46ba925ab4')"></el-table-column> -->
				<el-table-column prop="zoneWhNo" :label="$t('i18nn_f6283c46ba925ab4')"></el-table-column>

				<!-- <el-table-column prop="returnAddr" :label="$t('Storage.DropShipping.place_receipt')"></el-table-column> -->

				<el-table-column prop="batchNo" :label="'拣货单号'"></el-table-column>

				<!-- 
				<el-table-column prop="name" :label="$t('Storage.DropShipping.Addressee_name')"></el-table-column>

				<el-table-column prop="company" :label="$t('Storage.DropShipping.company')"></el-table-column>

				<el-table-column prop="phone" :label="$t('Storage.DropShipping.contact_way')"></el-table-column>

				<el-table-column prop="email" label="E-Mail"></el-table-column>
				<el-table-column prop="street1" :label="$t('Storage.DropShipping.Address_1')"></el-table-column>
				<el-table-column prop="street2" :label="$t('Storage.DropShipping.Address_2')"></el-table-column>
				<el-table-column prop="city" :label="$t('Storage.DropShipping.city')"></el-table-column>
				<el-table-column prop="state" :label="$t('Storage.DropShipping.State')"></el-table-column>
				<el-table-column prop="postalCode" :label="$t('Storage.DropShipping.postal_code')"></el-table-column>
				<el-table-column prop="country" :label="$t('Storage.DropShipping.country')"></el-table-column> -->
				<!-- <el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')" class="">
					<template slot-scope="scope">
						<div @click="openUploadFile(scope.row)">
							<div v-if="scope.row.attachments">
								<el-link type="primary">
									...
									<span>{{ $t('i18nn_73bbf51487ec69e9') }}</span>
									<span>{{ scope.row.attachments.length }}</span>
									<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column> -->

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<div>
							<!-- <ul> -->
							<!-- <li v-if="scope.row.attachments">
									<div @click="openUploadFile(scope.row)">
										<div>
											<el-link type="primary">
												...
												<span>{{ $t('i18nn_73bbf51487ec69e9') }}</span>
												<span>{{ scope.row.attachments.length }}</span>
												<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
											</el-link>
										</div>
									</div>
								</li> -->
							<!-- <li> -->
							<el-popover placement="top" trigger="hover">
								<div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference" style="color:red;">{{ scope.row.remark }}</span>
							</el-popover>
							<!-- </li> -->
							<!-- </ul> -->


						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="exprStatusTime" :label="$t('i18nn_d99d790ec4383bfb')"></el-table-column> -->

				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"></el-table-column> -->

				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{ $t('i18nn_0ae081c3b8c4d4a1') }}</span>:{{ scope.row.commitDate }}
								</li>
								<li>
									<span>{{ $t('i18nn_d99d790ec4383bfb') }}</span>:{{ scope.row.exprStatusTime }}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="220px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>
							<div style="margin-bottom: 5px;">
								<el-button v-if="'0' == scope.row.exprStatus" @click="commitAction($event, scope.row, scope.$index)"
									type="primary" size="small" icon="el-icon-sell">{{ $t('Storage.DropShipping.submit') }}</el-button>

								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="small"
									icon="el-icon-edit"
									v-if="'0' == scope.row.exprStatus || '5' == scope.row.exprStatus || '10' == scope.row.exprStatus">
									{{ $t("FormMsg.Edit") }}
								</el-button>

								<el-button v-if="'0' == scope.row.exprStatus || '90' == scope.row.exprStatus"
									@click="delAction($event, scope.row)" type="danger" size="small" icon="el-icon-minus">{{
					$t('FormMsg.Delete') }}</el-button>

								<el-button v-if="'5' == scope.row.exprStatus || '10' == scope.row.exprStatus"
									@click="revocationAction($event, scope.row)" type="danger" size="small"
									icon="el-icon-minus">{{ $t('i18nn_b4e5993f97fe04ee') }}</el-button>


								<el-button v-if="'3' == scope.row.exprStatus" @click="moveToAbnormal($event, scope.row)" type="danger"
									size="small" icon="el-icon-d-arrow-right">{{ $t('i18nn_084d695b245685c3') }}</el-button>


							</div>
							<!-- <div style="margin-bottom: 5px;" v-if="'0' == scope.row.exprStatus||'90' == scope.row.exprStatus">
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus" v-if="'0' == scope.row.exprStatus||'90' == scope.row.exprStatus">{{ $t('FormMsg.Delete') }}</el-button>
							</div> -->

							<!-- <div style="margin-bottom: 5px;">
								<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-upload2">{{ $t('FormMsg.upload_attachment') }}</el-button>
							</div> -->
							<!-- <div
								style="margin-bottom: 5px;"
								v-if="'10' == scope.row.exprStatus || '20' == scope.row.exprStatus || '30' == scope.row.exprStatus || '35' == scope.row.exprStatus"
							>
								<el-button @click="toOrderRateView($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-view">{{$t('i18nn_71c07829df8e6708')}}</el-button>
							
							</div> -->

							<div>



								<el-dropdown size="small" @command="rowMoreAction($event, scope.row, scope.$index)">
									<el-button type="primary" size="small" style="margin-right: 10px;">
										<span>{{ $t('i18nn_c3525eb110db58aa') }}</span><i class="el-icon-arrow-down el-icon--right"></i>
									</el-button>
									<el-dropdown-menu slot="dropdown">

										<!-- <el-dropdown-item command="openUploadFile" icon="el-icon-upload2">
											{{$t('FormMsg.upload_attachment')}}
										</el-dropdown-item> -->
										<el-dropdown-item divided command="toOrderRateView" icon="el-icon-document"
											v-if="('0' == scope.row.exprStatus || '10' == scope.row.exprStatus) && '20' == scope.row.isSelfLabel">
											{{ $t('i18nn_adea92839a4c22b0') }}
										</el-dropdown-item>
										<el-dropdown-item divided command="stopAction" icon="el-icon-video-pause"
											v-if="'10' == scope.row.exprStatus">
											{{ $t('i18nn_8eb6e92d013f9555') }}
										</el-dropdown-item>
										<el-dropdown-item divided command="stopRecoveryAction" icon="el-icon-video-play"
											v-if="'60' == scope.row.exprStatus && '10' == scope.row.stopStatus">
											{{ $t('i18nn_1a42987a0df962e9') }}
										</el-dropdown-item>
										<el-dropdown-item divided command="WrTrackingAction" icon="el-icon-edit"
											v-if="'40' != scope.row.exprStatus && '35' != scope.row.exprStatus && '90' != scope.row.exprStatus">
											{{ $t('i18nn_7f12d7a3ac88b55e') }}
										</el-dropdown-item>

										<el-dropdown-item divided command="openQARecList" icon="el-icon-service">
											{{ $t('i18nn_10bc8a2b45aa5636') }}
										</el-dropdown-item>

									</el-dropdown-menu>
								</el-dropdown>

								<el-button v-if="'5' == scope.row.exprStatus" @click="abnormalReSubmit($event, scope.row)" type="danger"
									size="small" icon="el-icon-document"
									style="margin-right: 10px;">{{ $t('i18nn_735df2dca58c4f9b') }}</el-button>

								<el-button @click="copyAction($event, scope.row, scope.$index)" type="success" size="small"
									icon="el-icon-copy-document" style="margin-right: 10px;"
									v-if="'0' == scope.row.exprStatus || '5' == scope.row.exprStatus || '10' == scope.row.exprStatus">{{ $t('i18nn_29f67f0644a78bf3') }}</el-button>

							</div>
							<!-- // 0,10,20 ->草稿,提交,已审核 -->
							<!-- <div style="margin-bottom: 5px;">
								
								<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-upload2">{{ $t('FormMsg.upload_attachment') }}</el-button>
									
								<el-button
									v-if="'0'==scope.row.exprStatus||'10'==scope.row.exprStatus||'20'==scope.row.exprStatus"
									@click="toOrderRateView($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-document">{{$t('i18nn_adea92839a4c22b0')}}</el-button>
							</div>


							<div style="margin-bottom: 5px;" v-if="'10' == scope.row.exprStatus">
								<el-button @click="stopAction($event, scope.row, scope.$index)" type="warning"
									size="mini" icon="el-icon-video-pause">{{$t('i18nn_8eb6e92d013f9555')}}</el-button>
							</div> -->

							<!-- 已暂停可以恢复 -->
							<!-- <div v-if="'60' == scope.row.exprStatus && '10' == scope.row.stopStatus"
								style="margin-bottom: 5px;">
								<el-button @click="stopRecoveryAction($event, scope.row, scope.$index)" type="warning"
									size="mini" icon="el-icon-video-play">{{$t('i18nn_1a42987a0df962e9')}}</el-button>
							</div>

							<div style="margin-bottom: 5px;">
								<el-button @click="WrTrackingAction($event, scope.row, scope.$index)" type="primary"
									size="mini" icon="el-icon-edit">
									{{ $t('i18nn_7f12d7a3ac88b55e') }}
								</el-button>
								<el-button @click="openQARecList($event, scope.row)"  type="primary" plain size="mini" icon="el-icon-service">{{$t('i18nn_10bc8a2b45aa5636')}}</el-button>
							</div> -->

							<!-- <div style="margin-bottom: 10px;">
								<el-button @click="openQARecList($event, scope.row)" type="info" size="mini" icon="el-icon-service">{{$t('i18nn_2582c3a44f7596c2')}}</el-button>
							</div> -->
							<!-- <div style="margin-bottom: 5px;" v-if="'0'!=scope.row.exprStatus && '10'!=scope.row.exprStatus">
								<el-button @click="toExpLgsTrackView($event, scope.row, scope.$index)" type="success"
									size="mini" icon="el-icon-search">{{$t('i18nn_7c3fc1953013745c')}}</el-button>
								
							</div> -->

						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="0" :title="dialogFormMsg" :visible.sync="dialogFormVisible" custom-class="myFullDialog4">
      <el-form ref="form" size="small" :rules="formRules" :model="form" label-width="100px" style="width: 1100px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">


        <h3 class="titSty1">{{$t('Storage.packing_list.basic_information')}}</h3>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('Storage.packing_list._no')" prop=""><el-input type="text" v-model="form.packingNo" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input></el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Storage.packing_list.Type')" prop="">
              <el-select filterable clearable size="mini" v-model="form.packageType" :placeholder="$t('FormMsg.Please_select')" style="width: 300px;">
                <el-option v-for="item in selectOption.wh_packing_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('Storage.packing_list.Departure_port')" prop=""><el-input type="text" v-model="form.leavePort" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input></el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Storage.packing_list.arrive_port')" prop=""><el-input type="text" v-model="form.arrivePort" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input></el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('Storage.packing_list.Departure_Date')" prop="">
              <el-date-picker v-model="form.leaveDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :placeholder="$t('FormMsg.Select_date_time')" style="width: 300px;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Storage.packing_list.arrive_Date')" prop="">
              <el-date-picker v-model="form.arriveDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :placeholder="$t('FormMsg.Select_date_time')" style="width: 300px;"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <h3 class="titSty1">{{$t('Storage.packing_list.detail')}}</h3>

        <div>
          <div style="margin-bottom: 10px;"><el-button type="primary" icon="el-icon-plus" size="mini" @click="addLe2()">{{$t('Storage.packing_list.add')}}</el-button></div>
          <div class="tableCon" v-loading="loading_det" :element-loading-text="$t('i18nn_b954f8829728e9d2')">
            <div class="tableConTable">
              <el-table border :data="form.packRecordList" style="">

                <el-table-column prop="goodsSku" :label="$t('Storage.skuInfo.product_information')" min-width="100">
                  <template slot-scope="scope">
                    <el-button type="success" size="mini" icon="el-icon-edit" @click="openSelData($event, scope.row, scope.$index)">{{$t("FormMsg.Select")}}</el-button>

                    <div>产品代码：{{ scope.row.goodsSku }}</div>
                    <div>{{$t('Storage.skuInfo.SKU_title')}}：{{ scope.row.goodsName }}</div>
                    <div>{{$t('Storage.skuInfo.price')}}：{{ scope.row.declarePrice }}</div>

                    <div>
                      <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
                        <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
                      </el-image>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="volumeUnit" :label="$t('Storage.skuInfo.Specification_information')" min-width="80">
                  <template slot-scope="scope">

                    <div class="tb-in-row">
                      <span class="tb-in-name">{{$t('Storage.skuInfo.weight')}}</span>
                      <span>{{ scope.row.goodsWeight }}</span>
                    </div>

                    <div class="tb-in-row">
                      <span class="tb-in-name">{{$t('Storage.skuInfo.unit')}}</span>
                      <el-select filterable clearable size="mini" v-model="scope.row.sizeUnit" :placeholder="$t('FormMsg.Please_select')" disabled style="width: 100px;">
                        <el-option v-for="item in selectOption.wh_size_unit" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
                      </el-select>
                    </div>

                    <div class="tb-in-row">
                      <span class="tb-in-name">{{$t('Storage.skuInfo.length')}}</span>
                      <span>{{ scope.row.goodsLength }}</span>
                    </div>
                    <div class="tb-in-row">
                      <span class="tb-in-name">{{$t('Storage.skuInfo.width')}}</span>
                      <span>{{ scope.row.goodsWidth }}</span>
                    </div>
                    <div class="tb-in-row">
                      <span class="tb-in-name">{{$t('Storage.skuInfo.height')}}</span>
                      <span>{{ scope.row.goodsHeight }}</span>
                    </div>

                  </template>
                </el-table-column>

                <el-table-column prop="" :label="$t('Storage.packing_list.packing_information')" width="400">
                  <template slot-scope="scope">
                    <div class="tb-in-row">
                      <span class="tb-in-name">{{$t('i18nn_1108b3e1ecd1fdce')}}</span>
                      <el-input size="mini" :placeholder="$t('FormMsg.Please_Enter')" v-model="scope.row.proxyCus" style="width: 200px;"></el-input>
                    </div>

                    <div class="tb-in-row" style="margin: 5px 0;">
                      <span class="tb-in-name">{{$t('Storage.packing_list.quantity')}}</span>
                      <el-input-number size="mini" v-model="scope.row.packCount" controls-position="right" style="width: 200px;"></el-input-number>
                    </div>

                    <div class="tb-in-row">
                      <span class="tb-in-name">{{$t('Storage.tableColumn.remark')}}</span>
                      <el-input type="textarea" size="mini" :placeholder="$t('FormMsg.Please_Enter')" v-model="scope.row.remark" maxlength="" show-word-limit s></el-input>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('Storage.tableColumn.operation')" width="100px" align="center">
                  <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-minus" @click="delLe2(scope.$index)">{{$t("FormMsg.Delete")}}</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <h3 class="titSty1">{{$t('Storage.packing_list.Other')}}</h3>
        <el-form-item :label="$t('Storage.tableColumn.remark')"><el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit></el-input></el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
        <el-button type="primary" @click="submitForm('form')">{{$t('FormMsg.Save')}}</el-button>
      </div>
    </el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->

		<!--选择入库数据-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0"> -->
		<!-- <div style=""><WSkuInfo :isSel="true" :status="'1'" @selectRow="selWhGoodsData"></WSkuInfo></div> -->
		<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogSelVisible = false">{{$t('FormMsg.Close')}}</el-button></div> -->
		<!-- </el-dialog> -->
		<!--录入运单-->
		<el-dialog :title="$t('i18nn_7f12d7a3ac88b55e')" :close-on-click-modal="false" append-to-body
			:visible.sync="dialogWrTracking" width="800px">
			<div>
				<!-- <el-alert title="请慎重修改,确保已和仓库已沟通." type="warning" show-icon>
				</el-alert> -->

				<el-form ref="WrTrackingData" :model="WrTrackingData" label-width="100px" v-loading="loading"
					:element-loading-text="$t('i18nn_1b4525c800280581')">
					<el-form-item :label="$t('i18nn_b0c50b6ff9674173')">
						<!-- <el-input size="" :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="WrTrackingData.carrier"></el-input> -->
						<el-select filterable clearable v-model="WrTrackingData.carrier" :placeholder="$t('i18nn_2ad108ab2c560530')"
							size="">
							<el-option v-for="item in selectOption.wh_carrier_code" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.codeText"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('i18nn_4531476fa35570f0')">
						<el-input size="" clearable :placeholder="$t('i18nn_5a9aefbc03c778f7')"
							v-model="WrTrackingData.tracking"></el-input>
					</el-form-item>

					<!-- 已提交可以修改url -->
					<el-form-item :label="$t('i18nn_1324a1d91ae3de53')" v-if="'10' == WrTrackingData.exprStatus">
						<el-input size="" clearable :placeholder="$t('i18nn_5a9aefbc03c778f7')"
							v-model.trim="WrTrackingData.labelUrl">
							<el-button slot="append" type="primary" icon="el-icon-upload2"
								@click="openUploadLabelFileModify()">上传</el-button>
						</el-input>
					</el-form-item>

					<el-form-item :label="$t('i18nn_d7315fb8114eb446')">
						<el-input-number size="" v-model="WrTrackingData.rate" controls-position="right">
						</el-input-number>$
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogWrTracking = false">{{ $t('i18nn_4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="submitWrTrackingForm()">{{ $t('i18nn_ad4345dbaabe1479') }}</el-button>
			</div>
		</el-dialog>

		<!--选择批量打单 快递公司-->
		<!-- <el-dialog :title="$t('i18nn_9f88e3e6b1e719bd')" append-to-body :close-on-click-modal="false" :visible.sync="dialogPrtOrderExpress" width="1000px" top="10px"> -->
		<prtOrderRateBatch :openTime="openTimeOrderRateBatch" :rows="selOrderRateBatchData" @success="OrderRateSuccess">
		</prtOrderRateBatch>

		<!-- <el-drawer :wrapperClosable="false" :title="$t('i18nn_9f88e3e6b1e719bd')" append-to-body
			:visible.sync="dialogPrtOrderExpress" :direction="'rtl'" size="1000px">
			<div v-loading="loading">
			
				<el-card v-for="(item, index) in PrtOrderExpressList" :key="index" :body-style="{ padding: '10px' }"
					shadow="never">
					<div style="display:flex;align-items: center;">
						<div class="">
							<el-radio v-model="PrtOrderExpressData" :label="item.code" :disabled="0 == item.isUse"
								@change="selLgsAction">
								<strong>{{ item.logistics }}({{ item.codeName }})</strong>
								<span v-if="0 == item.isUse" style="color: red;">X<span>{{$t('i18nn_ab5902ee5c645e01')}}</span></span>
							</el-radio>
						</div>
						<div v-if="item.descJson && item.descJson.length>0">
							<el-descriptions border size="mini">
								<el-descriptions-item :label="item2.name" v-for="(item2, index2) in item.descJson"
									:key="index2">{{ item2.value }}</el-descriptions-item>
							</el-descriptions>
						</div>
					</div>
				</el-card>


				<el-card style="margin-bottom: 10px;">
					<div slot="header"><strong>{{$t('i18nn_ecc3d56be2ae0baf')}}</strong></div>
					<div style="padding: 10px;display: flex;flex-wrap: wrap;">
						<div style="margin: 10px;">
							<span>{{$t('i18nn_7b16a04efec1728b')}}</span>：
							<el-input-number v-model="insuranceValue" controls-position="right"></el-input-number>
						</div>
						<div style="margin: 10px;">
							<span>{{$t('i18nn_7e80cfa637ec486b')}}</span>：
							<el-select filterable clearable v-model="signatureService" :placeholder="$t('i18nn_2ad108ab2c560530')"
								style="width: 200px;">
								<el-option v-for="(item,index) in selectOption.wh_signature_service" :key="index" :label="item.name"
									:value="item.key">
									<div class="sel_option_s1" style="">
										<span class="sel_option_name">{{ item.name }}</span>
										<span class="sel_option_code" style="">{{ item.key }}</span>
									</div>
								</el-option>
							</el-select>
						</div>
						<div style="margin: 10px;">
							<span>{{$t('i18nn_e716ca411b2142e3')}}</span>：
							<el-select filterable clearable v-model="channelsService" :placeholder="$t('i18nn_2ad108ab2c560530')"
								style="width: 200px;">
								<el-option v-for="(item,index) in selectOption.wh_channels" :key="index" :label="item.name"
									:value="item.key">
									<div class="sel_option_s1" style="">
										<span class="sel_option_name">{{ item.name }}</span>
										<span class="sel_option_code" style="">{{ item.key }}</span>
									</div>
								</el-option>
							</el-select>
						</div>

					</div>
				</el-card>
				
			</div>
			<div class="drawer-footer">
				<el-button type="primary" @click="batchSubmitPrintOrder()">{{ $t('i18nn_ad4345dbaabe1479') }}</el-button>
			</div>
		</el-drawer> -->
		<!-- </el-dialog> -->

		<!-- 订单号输入框 -->
		<el-dialog :title="$t('i18nn_33bb5e57aba192e1')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogOrderInputVisible" width="1000px">
			<div style="">
				<h3>{{ $t('i18nn_380912455c6e2f06') }}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.orderNumberListStr" size="small"
					clearable show-word-limit maxlength="10000" :placeholder="$t('i18nn_b92065474c13bf38')"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogOrderInputVisible = false">{{ $t('i18nn_4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureOrderInputAction()">{{ $t('i18nn_b5511889be42a3da') }}</el-button>
			</div>
		</el-dialog>

		<!-- 系统编号输入框 -->
		<el-dialog :title="'出库编号搜索'" append-to-body :close-on-click-modal="false" :visible.sync="dialogSysNoInputVisible"
			width="1000px">
			<div style="">
				<h3>{{ $t('i18nn_380912455c6e2f06') }}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.sysDefOrderNumListStr" size="small"
					clearable show-word-limit maxlength="10000" :placeholder="'一行输入一个'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSysNoInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureSysNoInputAction()">{{ $t('i18nn_b5511889be42a3da') }}</el-button>
			</div>
		</el-dialog>

		<!-- 快递单号输入框 -->
		<el-dialog :title="'快递单号搜索'" append-to-body :close-on-click-modal="false" :visible.sync="dialogTrackingInputVisible"
			width="1000px">
			<div style="">
				<h3>{{ $t('i18nn_380912455c6e2f06') }}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.trackingListStr" size="small"
					clearable show-word-limit maxlength="10000" :placeholder="'一行输入一个'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogTrackingInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureTrackingInputAction()">{{ $t('i18nn_b5511889be42a3da') }}</el-button>
			</div>
		</el-dialog>

		<!-- 详情查看 -->
		<WhDropShippingDet :openTime="openTimeDsDet" :row="DsDetRow"></WhDropShippingDet>

		<!-- 发货轨迹查询 -->
		<whQueryExpLgsTrack :openTime="openTimeExpLgsTrack" :row="ExpLgsTrackRow"></whQueryExpLgsTrack>
		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0"> -->
		<!-- <div style=""><InWarehouseDetList :packId="packId"></InWarehouseDetList></div> -->
		<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button></div> -->
		<!-- </el-dialog> -->
		<!-- 本地pdf分割 -->
		<pdfSplit :openTime="FilePdfSplitOpenTime"></pdfSplit>
		<!-- 本地pdf旋转 -->
		<pdfRotate :openTime="FilePdfRotateOpenTime"></pdfRotate>
		<!-- pdf合并 -->
		<pdfMerge :openTime="FilePdfMergeOpenTime"></pdfMerge>
		<!-- 图片转pdf -->
		<pdfConvertByImg :openTime="FilePdfConvertByImgOpenTime"></pdfConvertByImg>

		<!-- 打单费率 -->
		<prtOrderRate :openTime="openTimeOrderRate" :row="selOrderRateData" @actionSuccess="OrderRateSuccess">
		</prtOrderRate>
		<!-- 售后 -->
		<QAModelRecList :openTime="QAMoRecListOpenTime" :type="'10'" :relationId="QAMoRecListRelationId"
			:relationNo="QAMoRecListRelationNo" :whNo="QAMoRecListWhNo" @success="QAMoRecListSuccess"></QAModelRecList>

		<!-- 修改运单-上传附件面单  -->
		<whLabelFileUploadModify :openTime="FileLabelUploadModifyOpenTime" @success="FileLabelUploadModifySuccess">
		</whLabelFileUploadModify>

		<!-- 上传面单  -->
		<whLabelFileUpload :openTime="FileLabelUploadOpenTime" @success="FileLabelUploadSuccess"></whLabelFileUpload>


		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId"
			@success="FileUploadSuccess"></whFileUpload> -->

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhDropShippingPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
import {
	getDicData
} from '@/axios/common.js';

import {
	WarehouseDropShipping_status
} from '@/i18n/langStatus.js';
import {
	i18nStatus
} from '@/i18n/FormatI18n.js';

// import WSkuInfo from './WSkuProduct/WSkuInfo.vue';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import InWarehouseDetList from './InWarehouseDetList.vue';
import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
import whLabelFileUpload from '@/components/StorageCenter/shipments/whLabelFileUpload.vue';
import whLabelFileUploadModify from '@/components/StorageCenter/shipments/whLabelFileUploadModify.vue';
import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';

import pdfSplit from '@/components/StorageCenter/components/pdfSplit.vue';
import pdfRotate from '@/components/StorageCenter/components/pdfRotate.vue';
import pdfMerge from '@/components/StorageCenter/components/pdfMerge.vue';
import pdfConvertByImg from '@/components/StorageCenter/components/pdfConvertByImg.vue';

import QAModelRecList from '@/components/StorageCenter/QA/QAModelRecList.vue';

import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';

import prtOrderRate from '@/components/StorageCenter/components/prtOrderRate.vue';
import prtOrderRateBatch from '@/components/StorageCenter/components/prtOrderRateBatch.vue';

import WhDropShippingDet from '@/components/StorageCenter/shipments/WhDropShippingDet.vue';

import whQueryExpLgsTrack from '@/components/StorageCenter/components/whQueryExpLgsTrack.vue';

export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	// props: {
	//   // mobile:"",
	//   isSel: {
	//     default: function() {
	//       return false;
	//     },
	//     type: Boolean
	//   },
	//   status: {
	//     default: function() {
	//       return '';
	//     },
	//     type: String
	//   }
	// },
	components: {
		// WSkuInfo,
		// InWarehouseDetList,
		whLabelFileUpload,
		whLabelFileUploadModify,
		whFileUpload,
		pdfSplit,
		pdfRotate,
		pdfMerge,
		pdfConvertByImg,
		SelAgentUser,
		whExcelCustom,
		prtOrderRate,
		prtOrderRateBatch,
		whNoSelect,
		whQueryExpLgsTrack,
		QAModelRecList,
		WhDropShippingDet
	},
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},

			drawerFilter: false,

			FileLabelUploadOpenTime: '',
			// FileLabelUploadKey:'',
			FileLabelUploadModifyOpenTime: '',

			pickerOptions: this.$PickerDate.pickerOptions_1(),

			//订单号输入弹窗
			dialogOrderInputVisible: false,
			//系统编号输入弹窗
			dialogSysNoInputVisible: false,

			//快递单号输入弹窗
			dialogTrackingInputVisible: false,

			//售后
			QAMoRecListOpenTime: "",
			QAMoRecListRelationId: "",
			QAMoRecListRelationNo: "",
			// QAMoRecListUserId:"",
			QAMoRecListWhNo: "",
			// 要展开的行，数值的元素是row的key值
			// expands: [],
			// expands2: [],
			multipleSelection: [],

			openTimeDsDet: '',
			DsDetRow: {},

			openTimeExpLgsTrack: '',
			ExpLgsTrackRow: {},

			//选择批量打单 快递公司
			// dialogPrtOrderExpress: false,
			// PrtOrderExpressData: '',
			// PrtOrderExpressList: [],
			// insuranceValue: "", //  保险
			// signatureService: "", // 签名
			// channelsService: "", //渠道编码

			openTimeOrderRateBatch: '',
			selOrderRateBatchData: [],

			// dialogShowDet: false,
			// packId: null,
			FilePdfSplitOpenTime: '',
			FilePdfRotateOpenTime: '',
			FilePdfMergeOpenTime: '',
			FilePdfConvertByImgOpenTime: '',

			// fileRelationId: '',
			// FileUploadOpenTime: '',
			// FileUploadKey: '',

			//打单费率
			// selOrderRateData: {},

			//打单费率
			selOrderRateData: {},
			openTimeOrderRate: '',


			// selectOption: {
			// 	biz_wh_lgskey_vite_ups: []
			// },

			//打开入库选择框
			// dialogSelVisible: false,
			// openRowData: {},
			// openRowIndex: {},

			// UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			// dialogFormStatus: 0, //0-新增，1-修改
			// dialogFormMsg: '',

			//导入excel
			// dialogUploadVisible: false,
			// loadingExcel: false,
			// fileExcel: '',
			// excelData: [],
			// excelFileName: '',

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			loading_count: false,
			countData: {},

			loading: false,

			loading_det: false,

			// form: {
			//   id: '', //"数据ID",
			//   packingNo: '', //this.$t('i18nn_b7a10fe4e9528362'),
			//   leavePort: '', //this.$t('i18nn_ed47d0c198c61d01'),
			//   arrivePort: '', //this.$t('i18nn_d3d95bf498ae6cdb'),
			//   leaveDate: '', //this.$t('i18nn_04e92fdefcddec84'),
			//   arriveDate: '', //this.$t('i18nn_b5c03c6f1b9b1e28'),
			//   packageType: '', //this.$t('i18nn_b799f516e3940e3f'),
			//   packRecordList: [
			//     {
			//       goodsSku: '', //this.$t('i18nn_31f887c1ada79a99'),
			//       // cusCode: '', //this.$t('i18nn_64fed2851f79d875'),
			//       goodsName: '', //this.$t('i18nn_b23004db5349dfd2'),
			//       goodsNameEn: '', //this.$t('i18nn_05141ac0139eaf01'),
			//       goodsImg: '', //this.$t('i18nn_877c5a0e44a0eb07'),
			//       // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//       // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//       // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//       // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//       declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),
			//       goodsWeight: '', //this.$t('i18nn_11e41b2ec3d872ed'),
			//       // weightUnit: '', //this.$t('i18nn_8e01866868930d09'),
			//       sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
			//       goodsLength: '', //this.$t('i18nn_2cba96917484569a'),
			//       goodsWidth: '', //this.$t('i18nn_9e24e0e4745f4948'),
			//       goodsHeight: '', //this.$t('i18nn_c5fa0857bc0df2d6'),
			//       safeStock: '', //this.$t('i18nn_004617dc47191cb8'),
			//       packCount: '', //this.$t('i18nn_b9d4d3f6183bf3c1'),
			//       proxyCus: '' //货代客人
			//       // "id":"",//"数据id"
			//     }
			//   ]
			// },

			// formRules: {
			//   packingNo: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			//   leavePort: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			//   arrivePort: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			//   leaveDate: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
			//   arriveDate: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
			//   packageType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
			// },
			//录入运单
			dialogWrTracking: false,
			WrTrackingData: {},

			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
				// biz_wh_lgskey_vite_ups: [],
				// wh_signature_service: [],
				// wh_dic_signature_service: [],
				// wh_channels: [],
				// wh_no:[],
				wh_carrier_code: [],
				// wh_size_unit: [],
				// wh_packing_type: [],
				biz_wh_is_shelf_label: [],
				wh_exce_order_type: [],
				statusList: WarehouseDropShipping_status
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'N', //N降序，Y升序

				agentUser: '',
				// workNo: '',
				exprStatus: '',
				isSelfLabel: '',
				isPriority: '',
				isLabelUrl: '',
				orderNumber: '',
				commitDateArr: [],
				carrier: '',
				keyword: '',
				senderAddr: '',
				returnAddr: '',
				signatureService: '',

				wh_exce_order_type: "",

				orderNumberList: [],
				orderNumberListStr: '',

				sysDefOrderNum: '',
				sysDefOrderNumList: [],
				sysDefOrderNumListStr: '',

				tracking: '',
				trackingList: [],
				trackingListStr: '',
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
		// if (this.$route.query.workNo) {
		// 	this.filterData.workNo = this.$route.query.workNo;
		// 	// this.initData();
		// }
		if (this.$route.query) {
			if (this.$route.query.state) {
				this.filterData.exprStatus = this.$route.query.state;
			}
			if (this.$route.query.orderNumber) {
				this.filterData.orderNumber = this.$route.query.orderNumber;
			}
			if (this.$route.query.agentUser) {
				this.filterData.agentUser = this.$route.query.agentUser;
				this.$nextTick(() => {
					this.$refs.SelAgentUser.initData(this.filterData.agentUser);
				});
			}
		}

	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		//数据字典
		getDicData(['wh_carrier_code', 'wh_exce_order_type', 'biz_wh_is_shelf_label'],
			(data) => {
				this.selectOption.wh_carrier_code = data['wh_carrier_code'];
				// this.selectOption.wh_size_unit = data['wh_size_unit'];
				// this.selectOption.wh_packing_type = data['wh_packing_type'];
				this.selectOption.wh_exce_order_type = data['wh_exce_order_type'];
				this.selectOption.biz_wh_is_shelf_label = data['biz_wh_is_shelf_label'];
				// this.selectOption.wh_dic_signature_service = data['wh_signature_service'];
			});
		this.initData();
	},
	methods: {
		changeAgentUser(val) {
			this.filterData.agentUser = val;
			this.initData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.senderAddr = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		changWhNo2(data) {
			console.log('changWhNo', data);
			this.filterData.returnAddr = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},

		exportExcelAction() {
			// 工单号 日期 单号 快递公司 快递单号 面单地址  快递费用 收件人名  公司 联系方式 e-mail 地址1 地址2 城市 州/省 国家

			let columns = [{
				title: this.$t('Storage.packing_list.status'),
				key: 'exprStatusName'
			},
			{
				title: this.$t('i18nn_f6283c46ba925ab4'),
				key: 'senderAddr'
			},
			// {
			// 	title: "SKU CODE",
			// 	key: 'goodsSkuImg',
			// 	types: 'image'
			// },
			{
				title: this.$t('Storage.DropShipping.date'),
				key: 'commitDate'
			},
			{
				title: '拣货单号',
				key: 'batchNo'
			},

			{
				title: this.$t('i18nn_7f6c721b57ed0e7b'),
				key: 'sysDefOrderNum'
			},
			{
				title: this.$t('i18nn_abf9f4b8c93d641e'),
				key: 'orderNumber'
			},
			{
				title: this.$t('i18nn_83b1b50eb00a9fb6'),
				key: 'thirdOrderNumber'
			},
			{
				title: 'SKU',
				key: 'skuList',
				types: 'custom',
				formatter: function (row) {
					// let labelUrl_1 = '';
					// let labelUrl_2 = '';
					// let skuList = [];
					if (row.expressSkuList) {
						return row.expressSkuList
							.map(item => {
								// skuList.push(item.goodsSku);
								return item.goodsSku;
							})
							.join(',');
					} else {
						return '';
					}

					// return skuList.join(",");
				}
			},
			{
				title: this.$t('i18nn_f9db93b87e08763b'),
				key: 'quantity',
				types: 'custom',
				formatter: function (row) {
					return row.expressSkuList
						.map(item => {
							return item.quantity;
						})
						.join(',');
				}
			},

			{
				title: '单件' + this.$t('i18nn_96f1a4b17e75892d') + '(LB)',
				key: 'singleWeight',
				types: 'custom',
				formatter: function (row) {
					return row.expressSkuList
						.map(item => {
							return item.singleWeight;
						})
						.join(',');
				}
			},

			{
				title: '合计' + this.$t('i18nn_96f1a4b17e75892d') + '(LB)',
				key: 'totalWeight',
				types: 'custom',
				formatter: function (row) {
					return row.expressSkuList
						.map(item => {
							return item.totalWeight;
						})
						.join(',');
				}
			},
			{
				title: this.$t('i18nn_ea71165e067be24e') + '(LB)',
				key: 'countTotalWeight',
				types: 'custom',
				formatter: function (row) {
					return row.expressSkuList.
						reduce((prev, cur, index, arr) => {
							// console.log(prev, cur, index);
							return prev + parseFloat(cur.totalWeight);
						}, 0).toFixed(2);
					// return row.expressSkuList
					// 	.map(item => {
					// 		return item.totalWeight;
					// 	})
					// 	.join(',');
				}
			},

			{
				title: 'SKU' + this.$t('i18nn_98ea9b63e41e8543') + '(INC)',
				key: 'goodsLWH',
				types: 'custom',
				formatter: function (row) {
					if (row.expressSkuList) {
						return row.expressSkuList
							.map(item => {
								// skuList.push(item.goodsSku);
								if (item.mySkuDto) {
									return item.mySkuDto.goodsINCLength + ' * ' + item.mySkuDto.goodsINCWidth + ' * ' +
										item.mySkuDto.goodsINCHeight;;
								} else {
									return '';
								}
							})
							.join(' , \n');
					} else {
						return '';
					}
				}
			},

			{
				title: this.$t('i18nn_b0c50b6ff9674173'),
				key: 'carrier'
			},
			{
				title: this.$t('i18nn_581bc71c027386cb'),
				key: 'tracking'
			},
			{
				title: this.$t('i18nn_1324a1d91ae3de53'),
				key: 'labelUrl',
				types: 'custom',
				formatter: function (row) {
					// let labelUrl_1 = '';
					// let labelUrl_2 = '';
					let labelUrlArr = [];
					if (row.labelInfoDto && row.labelInfoDto.labelUrl) {
						labelUrlArr.push(row.labelInfoDto.labelUrl);
					}
					if (row.labelUrl) {
						labelUrlArr.push(row.labelUrl);
					}
					return labelUrlArr.join(',');
				}
			},
			{
				title: this.$t('i18nn_d7315fb8114eb446'),
				key: 'rate'
			},
			{
				title: this.$t('Storage.DropShipping.Addressee_name'),
				key: 'name'
			},
			{
				title: this.$t('Storage.DropShipping.company'),
				key: 'company'
			},
			{
				title: this.$t('Storage.DropShipping.contact_way'),
				key: 'phone'
			},
			{
				title: 'E-Mail',
				key: 'email'
			},
			{
				title: this.$t('Storage.DropShipping.Address_1'),
				key: 'street1'
			},
			{
				title: this.$t('Storage.DropShipping.Address_2'),
				key: 'street2'
			},
			{
				title: this.$t('Storage.DropShipping.city'),
				key: 'city'
			},
			{
				title: this.$t('Storage.DropShipping.State'),
				key: 'state'
			},
			{
				title: this.$t('Storage.DropShipping.country'),
				key: 'country'
			},
			{
				title: this.$t('Storage.DropShipping.postal_code'),
				key: 'postalCode'
			},
			{
				title: this.$t('Storage.packing_list.attachment'),
				key: 'attachments',
				types: 'custom',
				formatter: function (row) {
					return row.attachments
						.map(item => {
							return item.url;
						})
						.join(',');
				}
			},
			{
				title: this.$t('Storage.tableColumn.remark'),
				key: 'remark'
			},
				// {
				// 	title: this.$t('hytxs0000037'),
				// 	key: 'totalStock',
				// 	types: 'custom',
				// 	formatter:function(row){
				// 		if(row.placeStockTotal && row.placeStockTotal.totalStock){
				// 			return row.placeStockTotal.totalStock
				// 		} else {
				// 			return 0;
				// 		}

				// 	}
				// },
			];
			// let Data = this.tableDataCatch;
			// return columns;
			let Data = this.tableData;
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'wh_dropshipping';
			this.excelOption = {
				height: 20
			};
			this.excelOpenTime = new Date().getTime();
			// let data_dom = JQ('#ex_table').find(".el-table__body");
			// let obj_key_img = {};
			// // console.log(data_dom.find("tr"));
			// data_dom.find("tr").each((index, domEle)=>{
			// 	// console.log("index",index);
			// 	let imgObj = JQ(domEle).find("td .cell img");
			// 	// console.log("imgObj",imgObj);
			// 	if(imgObj){
			// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
			// 	}
			// })
			// // console.log("obj_key_img",obj_key_img);
			// Data.forEach(item=>{
			// 	item.goodsSkuImg = obj_key_img[item.goodsSku];
			// })
			// console.log('Data',Data);
			// return;
			// excelUtilsNew.exportExcel(columns, Data, 'wh_dropshipping',{height:20});
		},
		//表格点击行展开，在<table>里，我们已经设置row的key值设置为每行数据id：row-key="id"
		// rowClick(row, event, column) {
		//   // Array.prototype.remove = function(val) {
		//   //   let index = this.indexOf(val);
		//   //   if (index > -1) {
		//   //     this.splice(index, 1);
		//   //   }
		//   // };
		//   // console.log(this.expands);
		//   if (this.expands.indexOf(row.id) < 0) {
		//     this.expands.push(row.id);
		//   } else {
		//     // this.expands.remove(row.id);
		//     let index = this.expands.indexOf(row.id);
		//     if (index > -1) {
		//       this.expands.splice(index, 1);
		//     }
		//   }
		// },
		// rowClick2(row, event, column) {
		//   // Array.prototype.remove = function(val) {
		//   //   let index = this.indexOf(val);
		//   //   if (index > -1) {
		//   //     this.splice(index, 1);
		//   //   }
		//   // };
		//   // console.log(this.expands);
		//   if (this.expands2.indexOf(row.index) < 0) {
		//     this.expands2.push(row.index);
		//   } else {
		//     // this.expands.remove(row.id);
		//     let index = this.expands2.indexOf(row.index);
		//     if (index > -1) {
		//       this.expands2.splice(index, 1);
		//     }
		//   }
		// },
		//行标颜色
		tableRowClassName({ row, rowIndex }) {
			if (row.exprStatus == '50') {
				return 'warning-row';
			} else if (row.exprStatus == '60') {
				return 'danger-row';
			} else if (row.exprStatus == '90') {
				return 'info-row';
			} else if (row.exprStatus == '5') {
				return 'warning-row';
			}
			return '';
		},

		//选择行
		handleCurrentChange(row, event, column) {
			// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
		},
		//多选
		handleSelectionChange(val) {
			console.log(val);
			this.multipleSelection = val;
		},

		initData() {
			this.countData = {};
			this.getCountData();
			this.pagination.current_page = 1;
			// this.currentSel = {};
			this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},
		//打开更多筛选
		openMoreFilter() {
			this.drawerFilter = !this.drawerFilter;
		},
		//查询更多筛选
		queryMoreFilter() {
			this.initData();
			// this.drawerFilter = false;
		},
		i18nFormatter(value) {
			return i18nStatus(value, this.selectOption.statusList)
		},

		//打开订单的输入框
		openOrderInputAction() {
			// this.filterData.orderNumberListStr = "";
			this.dialogOrderInputVisible = true;
		},
		//确定订单的输入框
		sureOrderInputAction() {
			this.dialogOrderInputVisible = false;
			this.filterData.orderNumber = '';
			let orderNumberList = [];
			if (this.filterData.orderNumberListStr) {
				orderNumberList = this.filterData.orderNumberListStr.split('\n').filter(function (s) {
					return s && s.trim();
				});
			}
			this.filterData.orderNumberList = orderNumberList;
			this.initData();
		},
		//清除搜索订单号的值
		clearOrderList() {
			this.filterData.orderNumberListStr = '';
			this.filterData.orderNumberList = [];
			this.initData();
		},

		// 打开系统编号
		openSysNoInputAction() {
			this.dialogSysNoInputVisible = true;
		},
		// 确定系统编号
		sureSysNoInputAction() {
			this.dialogSysNoInputVisible = false;
			this.filterData.sysDefOrderNum = '';
			let sysDefOrderNumList = [];
			if (this.filterData.sysDefOrderNumListStr) {
				sysDefOrderNumList = this.filterData.sysDefOrderNumListStr.split('\n').filter(function (s) {
					return s && s.trim();
				});
			}
			this.filterData.sysDefOrderNumList = sysDefOrderNumList;
			this.initData();
		},
		//清除
		clearSysNoList() {
			this.filterData.sysDefOrderNumListStr = '';
			this.filterData.sysDefOrderNumList = [];
			// this.initData();
		},

		// 打开快递单号
		openTrackingInputAction() {
			this.dialogTrackingInputVisible = true;
		},
		// 确定快递单号
		sureTrackingInputAction() {
			this.dialogTrackingInputVisible = false;
			this.filterData.tracking = '';
			let trackingList = [];
			if (this.filterData.trackingListStr) {
				trackingList = this.filterData.trackingListStr.split('\n').filter(function (s) {
					return s && s.trim();
				});
			}
			this.filterData.trackingList = trackingList;
			this.initData();
		},
		//清除快递单号
		clearTrackingList() {
			this.filterData.trackingListStr = '';
			this.filterData.trackingList = [];
			// this.initData();
		},

		//行操作更多
		rowMoreAction(command, row, index) {
			console.log('rowMoreAction', command);
			// event.stopPropagation();
			// if ('openUploadFile' == command) {
			// 	this.openUploadFile(row, index);
			// } else 
			if ('toOrderRateView' == command) {
				this.toOrderRateView(row, index);
			} else if ('stopAction' == command) {
				this.stopAction(row, index);
			} else if ('stopRecoveryAction' == command) {
				this.stopRecoveryAction(row, index);
			} else if ('WrTrackingAction' == command) {
				this.WrTrackingAction(row, index);
			} else if ('openQARecList' == command) {
				this.openQARecList(row, index);
			}


			// else if('toExpLgsTrackView'==command){
			// 	this.toExpLgsTrackView(row,index);
			// }
		},
		//打开新增编辑，弹窗
		// openDioalog(formParm, msg) {
		//   // console.log(formParm);
		//   this.dialogFormVisible = true;
		//   let form = Object.assign({}, formParm);
		//   console.log('form', form);
		//   this.dialogFormMsg = msg;
		//   // // 重置
		//   this.resetForm('form');
		//   if (null === formParm) {
		//     //新增
		//     this.dialogFormStatus = 0;
		//     //浅拷贝、对象属性的合并
		//     this.form = form;
		//   } else {
		//     //修改
		//     this.dialogFormStatus = 1;
		//     // form.state = form.state === '0' ? true : false;
		//     //浅拷贝、对象属性的合并
		//     this.form = form;
		//   }
		//   this.$forceUpdate();
		// },
		//打开编辑
		openEdit(event, row, index) {
			event.stopPropagation();
			// this.openDioalog(row, this.$t('FormMsg.Edit'));
			// this.getPageDetData(row.id);
			this.$router.push({
				name: "createOrder",
				query: {
					orderType: '40',
					single: true,
					id: row.id
				}
			})
		},

		//复制
		copyAction(event, row, index) {
			event.stopPropagation();
			// this.openDioalog(row, this.$t('FormMsg.Edit'));
			// this.getPageDetData(row.id);
			this.$router.push({
				name: "createOrder",
				query: {
					// cusUserId:row.userId,
					orderType: '40',
					single: true,
					id: row.id,
					copy: '1'
				}
			})
		},
		//明细
		//请求分页数据
		// getPageDetData(packId) {
		//   // let _this = this;
		//   this.loading_det = true;
		//   this.$http
		//     .put(this.$urlConfig.WhDropShippingPageList, {
		//       offset: 0, //当前页
		//       limit: 100, //当前页显示数目
		//       packId: packId
		//     })
		//     .then(({ data }) => {
		//       console.log('分页，请求成功');
		//       console.log(data);
		//       
		//       this.loading_det = false;
		//       //表格显示数据
		//       this.form.packRecordList = data.rows;
		//     })
		//     .catch(error => {
		//       console.log(error);
		//       console.log('分页，请求失败');
		//       this.loading_det = false;
		//     });
		// },
		//去详情
		toDetActionView(event, row) {
			event.stopPropagation();
			// this.$router.push({ name: 'WhDropShippingDet', query: { orderNumber: row.orderNumber } });
			this.openTimeDsDet = new Date().getTime();
			this.DsDetRow = row;
		},

		//发货单轨迹查询
		toExpLgsTrackView(event, row) {
			event.stopPropagation();
			// this.$router.push({ name: 'WhDropShippingDet', query: { orderNumber: row.orderNumber } });
			// this.openTimeExpLgsTrack = new Date().getTime();
			// this.ExpLgsTrackRow = row;
			if (row.carrier && row.tracking) {
				this.openTimeExpLgsTrack = new Date().getTime();
				this.ExpLgsTrackRow = row;
			} else {
				this.$message.warning(this.$t('tips.no_sel_express_company_order'));
			}
		},

		//pdf处理
		handleMoreAction(command) {
			// event.stopPropagation();
			// this.FilePdfRotateOpenTime = new Date().getTime();
			if ('openLocalPdfSplit' == command) {
				this.openLocalPdfSplit();
			} else if ('openLocalPdfRotate' == command) {
				this.openLocalPdfRotate();
			} else if ('openLocalPdfMerge' == command) {
				this.openLocalPdfMerge();
			} else if ('openLocalImgConvertPdf' == command) {
				this.openLocalImgConvertPdf();
			}

		},
		//本地pdf分割
		openLocalPdfSplit() {
			// event.stopPropagation();
			this.FilePdfSplitOpenTime = new Date().getTime();
		},
		//本地pdf旋转
		openLocalPdfRotate() {
			// event.stopPropagation();
			this.FilePdfRotateOpenTime = new Date().getTime();
		},
		//pdf合并
		openLocalPdfMerge() {
			// event.stopPropagation();
			this.FilePdfMergeOpenTime = new Date().getTime();
		},
		//图片转pdf
		openLocalImgConvertPdf() {
			this.FilePdfConvertByImgOpenTime = new Date().getTime();
		},

		//打开附件上传
		// openUploadFile(row) {
		// 	// event.stopPropagation();
		// 	this.fileRelationId = row.id;
		// 	// this.FileUploadKey = row.orderNumber;
		// 	this.FileUploadOpenTime = new Date().getTime();
		// },

		// //附件上传成功回调
		// FileUploadSuccess(data) {
		// 	this.initData();
		// },

		//删除
		delAction(event, row) {
			event.stopPropagation();
			this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					this.delDataAction(event, row);
				})
				.catch(() => { });
		},
		//删除
		delDataAction(event, row) {
			event.stopPropagation();
			console.log('delDataAction', row);

			this.postData(this.$urlConfig.WhDropShippingDel + '/' + row.id, {}, 'delete');
		},

		//撤销
		revocationAction(event, row) {
			event.stopPropagation();
			let parm = [];

			if (row) {
				//     //单条
				parm = [row.id];
			} else {
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
					return;
				}
				// if (dataList.findIndex(item => '0' != item.exprStatus) > -1) {
				// 	this.$message.warning(this.$t('Storage.DropShipping.sel_tips_2'));
				// 	return;
				// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// console.log('dataListParm', dataListParm);
				// let dataParm = {
				//   ids: dataListParm
				// };
			}
			this.$confirm(
				this.$t('i18nn_0174ec83eeb25914') + '?<br />'
				+ '撤销后订单不可见,<br />'
				+ '如果使用<span class="red">系统出单</span>,<br />'
				+ '撤销订单<span class="red">不会同时取消面单</span>,<br />'
				+ '若有需要,请去操作撤销/取消面单.',
				this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				dangerouslyUseHTMLString: true,
				type: 'warning'
			})
				.then(() => {
					// this.delDataAction(event, row);
					// let ids = [row.id];
					this.postData(this.$urlConfig.WhDropShippingRevoke, {
						"ids": parm
					}, '');
				})
				.catch(() => { });
		},

		//移至异常
		moveToAbnormal(event, row) {
			event.stopPropagation();
			let parm = [];

			if (row) {
				//     //单条
				parm = [row.id];
			} else {
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
					return;
				}
				// if (dataList.findIndex(item => '0' != item.exprStatus) > -1) {
				// 	this.$message.warning(this.$t('Storage.DropShipping.sel_tips_2'));
				// 	return;
				// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// console.log('dataListParm', dataListParm);
				// let dataParm = {
				//   ids: dataListParm
				// };
			}
			this.$confirm(this.$t('i18nn_c7e739a5ba70db03') + '?', this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					// this.delDataAction(event, row);
					// let ids = [row.id];
					this.postData(this.$urlConfig.WhDropShippingMoveFail, {
						"ids": parm
					}, '');
				})
				.catch(() => { });
		},

		// 异常重新提交
		abnormalReSubmit(event, row) {
			event.stopPropagation();
			let parm = [];

			if (row) {
				//     //单条
				parm = [row.id];
			} else {
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
					return;
				}
				// if (dataList.findIndex(item => '0' != item.exprStatus) > -1) {
				// 	this.$message.warning(this.$t('Storage.DropShipping.sel_tips_2'));
				// 	return;
				// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// console.log('dataListParm', dataListParm);
				// let dataParm = {
				//   ids: dataListParm
				// };
			}
			this.$confirm(this.$t('i18nn_412f179fdcd6871c') + '?', this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					// this.delDataAction(event, row);
					// let ids = [row.id];
					this.postData(this.$urlConfig.WhDropShippingReSubmit, {
						"ids": parm
					}, '');
				})
				.catch(() => { });
		},

		//取消发货
		// cancelDeliveryAction(event, row){
		// 	event.stopPropagation();
		// 	this.$confirm("确定取消发货吗？", this.$t('tips.tipsTitle'), {
		// 	  // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 	  // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		// 	  type: 'warning'
		// 	})
		// 	  .then(() => {
		// 	    this.cancelDeliveryDataAction(event, row);
		// 	  })
		// 	  .catch(() => {});
		// },
		// //取消发货
		// cancelDeliveryDataAction(event, row) {
		//   event.stopPropagation();
		//   console.log('delDataAction', row);

		//   this.postData(this.$urlConfig.WhDropShippingCancel + '/' + row.id, {});
		// },
		//暂停
		stopAction(row) {
			// event.stopPropagation();
			this.$confirm(this.$t('tips.confirmSubmit'), this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					this.stopDataAction(event, row);
				})
				.catch(() => { });
		},
		//暂停
		stopDataAction(event, row) {
			event.stopPropagation();
			console.log('stopDataAction', row);
			let parm = [row.id];
			// this.postData(this.$urlConfig.WhDropShippingStop + '/' + row.id, {});
			this.postData(this.$urlConfig.WhDropShippingStop, parm);
		},

		//恢复暂停
		stopRecoveryAction(row) {
			// event.stopPropagation();
			// let parm = [row.id];
			let parm = [];

			if (row) {
				//     //单条
				parm = [row.id];
			} else {
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('7b80e66b535a3732'));
					return;
				}
				// if (dataList.findIndex(item => '10' != item.exprStatus) > -1) {
				// 	this.$message.warning(this.$t('i18nn_7b23bac6ffe353b9'));
				// 	return;
				// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
			}
			// this.$confirm(this.$t('i18nn_204ffab8a6e01870') + parm.length + '条数据 恢复吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
			this.$confirm(this.$t('tips.confirmSubmit'), this.$t('i18nn_daaaeb1b7b22b126'), {
				//
				//
				type: 'warning'
			})
				.then(() => {
					this.postData(this.$urlConfig.whDropShippingStopRecovery, parm)
				})
				.catch(() => { });
		},

		//录入运单
		WrTrackingAction(row) {
			// event.stopPropagation();

			this.WrTrackingData = {
				id: row.id,
				exprStatus: row.exprStatus ? row.exprStatus : '',
				carrier: row.carrier ? row.carrier : '',
				tracking: row.tracking ? row.tracking : '',
				labelUrl: row.labelUrl ? row.labelUrl : '',
				rate: row.rate ? row.rate : 0
			};

			this.dialogWrTracking = true;


		},
		//提交录入的运单
		submitWrTrackingForm() {
			let parm = this.WrTrackingData;
			// this.$confirm('确定提交 录入运单 吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
			//     //
			//     //
			//     type: 'warning'
			//   })
			//     .then(() => {
			this.postData(this.$urlConfig.WhDropShippingWrTracking + '/' + this.WrTrackingData.id, parm);
			// })
			// .catch(() => {
			//   // this.$message({
			//   //   type: 'info',
			//   //   message: this.$t('i18nn_2e58cb9b52e2a214')
			//   // });
			// });
		},
		//发货
		// shipmentsAction(event, row) {
		//   this.$confirm('确定发货吗?', this.$t('tips.tipsTitle'), {
		//     // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   })
		//     .then(() => {
		//       this.postData(this.$urlConfig.WhPackingListShipments + '/' + row.id, {}, () => {
		//         this.$message.success('发货成功！');
		//       });
		//     })
		//     .catch(() => {});
		// },

		//打开label上传
		openUploadLabelFile() {
			// event.stopPropagation();
			// this.fileWorkNo = row.workNo;
			// this.FileUploadKey = row.orderNumber;
			this.FileLabelUploadOpenTime = new Date().getTime();
		},

		//label上传成功回调
		FileLabelUploadSuccess(data) {
			this.initData();
			// console.log(data);
		},

		//打开修改面单的上传
		openUploadLabelFileModify() {
			// event.stopPropagation();
			// this.fileWorkNo = row.workNo;
			// this.FileUploadKey = row.orderNumber;
			this.FileLabelUploadModifyOpenTime = new Date().getTime();
		},

		//label上传成功回调
		FileLabelUploadModifySuccess(data) {
			// this.initData();
			// console.log(data);
			this.WrTrackingData.labelUrl = data;
			// this.$message
		},



		//打单费率查询
		toOrderRateView(row, index) {
			// event.stopPropagation();
			// this.PriterOrderRate_show = true;
			this.selOrderRateData = row;
			this.openTimeOrderRate = new Date().getTime();
			// this.PriterOrderRate = [];
			// if('1'==this.selOrderRateExpressData){//fedex
			// 	this.getPriterOrderRate(this.selOrderRateData.id);
			// } else if('2'==this.selOrderRateExpressData){//ups
			// 	this.getPriterOrderUpsRate(this.selOrderRateData.id);
			// }
			// this.ChangeSelOrderRateExpressData();
		},
		//打单费率查询-回调
		OrderRateSuccess(data) {
			this.initData();
			// this.PriterOrderRate_show = false;
		},

		// //选择渠道
		// selLgsAction() {
		// 	let selLgsData = this.PrtOrderExpressList.find(item => {
		// 		return this.PrtOrderExpressData == item.code;
		// 	});
		// 	//签名
		// 	if (!!selLgsData.extendParamJson && !!selLgsData.extendParamJson.signatures) {
		// 		this.selectOption.wh_signature_service = selLgsData.extendParamJson.signatures;
		// 	} else {
		// 		this.selectOption.wh_signature_service = [];
		// 	}
		// 	this.signatureService = null;
		// 	//渠道编码
		// 	if (!!selLgsData.extendParamJson && !!selLgsData.extendParamJson.signatures) {
		// 		this.selectOption.wh_channels = selLgsData.extendParamJson.channels;
		// 		if (this.selectOption.wh_channels && this.selectOption.wh_channels.length > 0) {
		// 			this.channelsService = this.selectOption.wh_channels[0].key;
		// 		}
		// 	} else {
		// 		this.selectOption.wh_channels = [];
		// 	}
		// },

		//提交
		commitAction(event, row) {
			event.stopPropagation();

			let parm = [];

			if (row) {
				//     //单条
				parm = [row.id];
			} else {
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
					return;
				}
				if (dataList.findIndex(item => '0' != item.exprStatus) > -1) {
					this.$message.warning(this.$t('Storage.DropShipping.sel_tips_2'));
					return;
				}

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// console.log('dataListParm', dataListParm);
				// let dataParm = {
				//   ids: dataListParm
				// };
			}

			this.$confirm(this.$t('Storage.DropShipping.sure_submit_1') + parm.length + this.$t(
				'Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					this.postData(this.$urlConfig.WhDropShippingSubmit, parm);
				})
				.catch(() => { });
		},

		//售后
		openQARecList(row) {
			// event.stopPropagation();
			this.QAMoRecListRelationId = row.id;
			this.QAMoRecListRelationNo = row.orderNumber;
			// this.QAMoRecListUserId = row.userId;
			this.QAMoRecListWhNo = row.senderAddr;
			this.QAMoRecListOpenTime = new Date().getTime();
		},
		QAMoRecListSuccess() {
			this.initData();
		},


		//提交信息
		postData(url, formData, type) {
			// HttpTypelet _this = this;
			this.loading = true;
			this.loading_load = true;
			let HttpType = {};
			if ('delete' == type) {
				HttpType = this.$http.delete(url, formData);
			} else {
				HttpType = this.$http.put(url, formData);
			}
			HttpType.then(({
				data
			}) => {
				console.log(this.$t('i18nn_bc868e024b80d2e3'));
				console.log(data);

				this.loading = false;
				this.loading_load = false;
				if (200 == data.code) {
					this.dialogFormVisible = false;
					this.dialogWrTracking = false;
					// this.dialogPrtOrderExpress = false;
					this.getPageData();
					if ('delete' == type) {
						this.$message({
							type: 'success',
							message: this.$t('tips.successfullyDelete')
						});
					} else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
					}
				} else {
					this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				this.loading_load = false;
				this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				});
			});
		},

		//打开导入
		toCreate() {
			this.$router.push({
				name: 'createOrder',
				query: {
					orderType: '40'
				}
			});
			
		},
		
		//统计状态数量
		getCountData() {
			this.loading_count = true;
			this.$http
				.put(this.$urlConfig.WhDropShippingStatusStatistics, {})
				.then(({
					data
				}) => {

					this.loading_count = false;
					if (200 == data.code) {
						//表格显示数据
						this.countData = data.data;
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
					}
				})
				.catch(error => {
					console.log(error);
					this.$message.error(this.$t('tips.queryRequestFailed'));
					this.loading_count = false;
				});
		},
		
		handleSelectStatus(key, keyPath) {
			// console.log(key, keyPath);
			this.filterData.exprStatus = key;
			this.initData();
		},
		//分页的筛选项数据
		pageFilterData() {

			let commitDateArr = this.filterData.commitDateArr;
			let startCommitDate = '';
			let endCommitDate = '';
			if (commitDateArr && commitDateArr.length == 2) {
				startCommitDate = commitDateArr[0];
				endCommitDate = commitDateArr[1];
			}

			let orderNumberList = [];

			if (this.filterData.orderNumberListStr) {
				orderNumberList = this.filterData.orderNumberList;
			} else if (this.filterData.orderNumber) {
				orderNumberList = [this.filterData.orderNumber];
			}

			let sysDefOrderNumList = [];
			if (this.filterData.sysDefOrderNumListStr) {
				sysDefOrderNumList = this.filterData.sysDefOrderNumList;
			} else if (this.filterData.sysDefOrderNum) {
				sysDefOrderNumList = [this.filterData.sysDefOrderNum];
			}

			let trackingList = [];
			if (this.filterData.trackingListStr) {
				trackingList = this.filterData.trackingList;
			} else if (this.filterData.tracking) {
				trackingList = [this.filterData.tracking];
			}

			return {
				"sortAsc": this.filterData.sortAsc,
				"orderBy": this.filterData.orderBy,
				// workNo: this.filterData.workNo ? this.filterData.workNo : null,
				proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
				exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,

				startCommitDate: startCommitDate ? startCommitDate : null,
				endCommitDate: endCommitDate ? endCommitDate : null,

				// orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
				orderNumberList: orderNumberList.length > 0 ? orderNumberList : null,
				sysDefOrderNumList: sysDefOrderNumList.length > 0 ? sysDefOrderNumList : null,
				trackingList: trackingList.length > 0 ? trackingList : null,

				keyword: this.filterData.keyword ? this.filterData.keyword : null,
				carrier: this.filterData.carrier ? this.filterData.carrier : null,
				// senderAddr: this.filterData.senderAddr ? this.filterData.senderAddr : null,
				zoneWhNo: this.filterData.senderAddr ? this.filterData.senderAddr : null,

				orderType: this.filterData.wh_exce_order_type ? this.filterData.wh_exce_order_type : null,

				returnAddr: this.filterData.returnAddr ? this.filterData.returnAddr : null,
				isSelfLabel: this.filterData.isSelfLabel ? this.filterData.isSelfLabel : null,
				isPriority: this.filterData.isPriority ? this.filterData.isPriority : null,
				isLabelUrl: this.filterData.isLabelUrl ? this.filterData.isLabelUrl : null,
				signatureService: this.filterData.signatureService ? this.filterData.signatureService : null,
				
			};
		},
		//请求分页数据
		getPageData() {
			let filterData = Object.assign({
				offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
				limit: this.pagination.page_size, //当前页显示数目

			},
				this.pageFilterData()
			);
			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhDropShippingPageList, filterData)
				.then(({
					data
				}) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);

					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},

		//打开弹窗选择 快递公司-批量打单
		openBatchPrtOrder() {
			let dataList = this.multipleSelection;
			if (dataList.length < 1) {
				this.$message.warning(this.$t('tips.Please_Sel'));
				return;
			}
			// 0,10,20 ->草稿,提交,已审核
			// console.log(dataList.findIndex(item=> ('0'!==item.status && '10'!==item.status && '20'!==item.status)))
			if (dataList.findIndex(item => ('0' !== item.exprStatus && '10' !== item.exprStatus && '20' !== item
				.exprStatus)) > -1) {
				this.$message.warning(this.$t('tips.long_WarehouseDropShipping_1'));
				return;
			}

			this.selOrderRateBatchData = this.multipleSelection;
			this.openTimeOrderRateBatch = new Date().getTime();

		},

		
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>